import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import NewsletterRow from "../newsletter/NewsletterRow";
// import PageTitle from "../core/PageTitle";
import React from "react";
import Layout from "../components/core/Layout";

function ErrorPage() {
  const classes = useStyles();
  return (
    <Layout
      maxWidth="lg"
      pageTitle="Jetpack Compose Quick Bites | JetpackCompose.app by Vinay Gaba"
      pageDescription="Learn Jetpack Compose with easy to consume bites of knowledge!"
      pageSlug="/"
      pageImageUrl="/landing.png"
      seoImageUrl="/landing.png"
    >
      <div className={classes.root}>
        <img src="/404_error.png" className={classes.image}></img>
      </div>
    </Layout>
  );
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    padding: 64,
    maxWidth: "50%",
  },
});

export default ErrorPage;
